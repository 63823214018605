<template>
  <app-layout-main class="p-4">
    <div
      class="
        container
        m-auto
        md:px-8
        max-w-xl max-h-90
        space-y-20
        bg-contain bg-app-logo bg-no-repeat bg-center
        pb-20
      "
    >
      <app-logo-payje />

      <br />

      <app-card class="-top-8 m-6">
        <h3 class="mb-4 font-semibold md:text-lg text-center">
          {{ $t("auth.register.resend_email_title") }}
        </h3>
        <p class="mb-4 text-center text-gray-500 text-sm">
          {{ $t("auth.register.rensend_email_description") }}
        </p>

        <app-button :loading="loading" @onClick="forgotPassword">
          {{ $t("auth.login.resend_email") }}
        </app-button>
      </app-card>
    </div>
    <app-alert />
  </app-layout-main>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters["authStore/user"];
    },

    loading() {
      return this.$store.getters["authStore/loading"];
    },

    errors() {
      const storeErrors = this.$store.getters["authStore/errors"];
      if (this.$lodash.isEmpty(storeErrors)) {
        return this.emptyErrors;
      }
      return storeErrors;
    },
  },
  mounted() {
    let email = this.$route.params.email;

    if (email) {
      this.user.email = email;
    } else {
      this.$router.push({ name: "forgot-password" });
    }
  },
  methods: {
    async forgotPassword() {
      this.resetState();
      const resetPasswordResult = await this.$store.dispatch(
        "authStore/forgotPassword",
        this.user
      );

      if (resetPasswordResult == null) {
        this.$notify(
          {
            group: "error",
            title: this.$t("general.email_error"),
          },
          5000
        );
        return;
      }

      this.$notify(
        {
          group: "success",
          title: this.$t("general.email_sent"),
        },
        5000
      );
    },

    resetState() {
      this.$store.commit("authStore/resetState");
    },
  },
};
</script>